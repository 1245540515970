import { AntTable, SVGIcon } from '@components';
import {
  addAppsForPartnerMember,
  listAppsPartnerAdmin,
  listAppsPartnerMember,
  setAccessPartnerMember,
} from '@services/manage-user/manage-user';
import { ConnectTypeEnum, ListAppPartnerAdminDocumentType, ListAppPartnerMemberDocumentType, PageProps } from '@types';
import { Button, Input, message, Spin, Switch, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './TableListApps.scss';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ModalAddApps } from '../modal/ModalAddApps';

const { Text } = Typography;

export const TableListApps: React.FC<PageProps> = () => {
  const [listDataMember, setListDataMember] = useState<ListAppPartnerMemberDocumentType[]>([]);
  const [listDataAdmin, setListDataAdmin] = useState<ListAppPartnerAdminDocumentType[]>([]);
  const [filteredDataMember, setFilteredDataMember] = useState<ListAppPartnerMemberDocumentType[]>([]);
  const [filteredAdminApps, setFilteredAdminApps] = useState<ListAppPartnerAdminDocumentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { username } = useParams<{ username: string }>();
  const [searchAppId, setSearchAppId] = useState<string>('');
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [loadingAddApp, setLoadingAddApp] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (!username) return;
      try {
        const [dataMember, dataAdmin] = await Promise.all([listAppsPartnerMember(username), listAppsPartnerAdmin()]);
        if (dataMember.code === 0) {
          setListDataMember(dataMember.data);
          setFilteredDataMember(dataMember.data);
        }
        if (dataAdmin.code === 0) setListDataAdmin(dataAdmin.data);
      } catch (error) {
        console.error('Error fetching apps data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  useEffect(() => {
    const filteredData = listDataMember.filter((item) => item.app_id.toLowerCase().includes(searchAppId.toLowerCase()));
    setFilteredDataMember(filteredData);
  }, [searchAppId, listDataMember]);

  useEffect(() => {
    const memberAppIds = listDataMember.map((member) => member.app_id);
    const uniqueAdminApps = listDataAdmin.filter((admin) => !memberAppIds.includes(admin.app_id));
    setFilteredAdminApps(uniqueAdminApps);
  }, [listDataAdmin, listDataMember]);

  const handleAddApp = async ({ username, appId }: { username: string; appId: string }) => {
    setLoadingAddApp(true);
    try {
      await addAppsForPartnerMember(username, appId);
      const dataMember = await listAppsPartnerMember(username);
      if (dataMember.code === 0) {
        setListDataMember(dataMember.data);
        setFilteredDataMember(dataMember.data);
      }
      setOpenAddModal(false);
      message.success('App added successfully!');
    } catch (error) {
      message.error('Failed to add app. Please try again.');
    } finally {
      setLoadingAddApp(false);
    }
  };

  const renderSwitch = (app_id: string, value: boolean, connectType: ConnectTypeEnum) => {
    const roleData = localStorage.getItem('ADMIN');
    let role = '';

    if (roleData) {
      try {
        const parsedRole = JSON.parse(roleData);
        role = parsedRole.role;
      } catch (error) {
        console.error('Failed to parse role from localStorage:', error);
      }
    }

    const isPartnerAdmin = role === 'PARTNER_ADMIN';

    // Lấy app từ listDataAdmin có app_id trùng khớp
    const adminApp = listDataAdmin.find((admin) => admin.app_id === app_id);

    // Lấy trạng thái của ga_connected và shopify_connected
    const gaConnected = adminApp?.ga_connected ?? false; // Mặc định là false nếu không tìm thấy
    const shopifyConnected = adminApp?.shopify_connected ?? false; // Mặc định là false nếu không tìm thấy

    // Kiểm tra điều kiện disable dựa trên connectType và trạng thái trong listDataAdmin
    const isDisabled =
      (connectType === ConnectTypeEnum.GA && !gaConnected) ||
      (connectType === ConnectTypeEnum.SHOPIFY && !shopifyConnected);

    const isAppInAdminList = listDataAdmin.some((admin) => admin.app_id === app_id);
    const isAppInMemberList = listDataMember.some((member) => member.app_id === app_id);

    const canEditSwitch = isPartnerAdmin && isAppInAdminList && isAppInMemberList && !isDisabled;

    const handleSwitchChange = async (checked: boolean) => {
      try {
        await setAccessPartnerMember(username!, app_id, connectType, checked);
        message.success(
          `${connectType.charAt(0).toUpperCase()}${connectType.slice(1).toLowerCase()} updated successfully!`,
        );

        const updatedListDataMember = listDataMember.map((member) =>
          member.app_id === app_id ? { ...member, [`${connectType.toLowerCase()}_connected`]: checked } : member,
        );
        const filteredData = updatedListDataMember.filter((item) =>
          item.app_id.toLowerCase().includes(searchAppId.toLowerCase()),
        );
        setListDataMember(updatedListDataMember);
        setFilteredDataMember(filteredData);
      } catch (error) {
        message.error(
          `Failed to update ${connectType.charAt(0).toUpperCase()}${connectType
            .slice(1)
            .toLowerCase()}. Please try again.`,
        );
      }
    };

    return (
      <Switch checked={value} onChange={canEditSwitch ? handleSwitchChange : undefined} disabled={!canEditSwitch} />
    );
  };

  const columns: ColumnsType<ListAppPartnerMemberDocumentType> = [
    {
      title: 'App ID',
      dataIndex: 'app_id',
      key: 'app_id',
      render: (value: string) => (
        <Text>
          <a className="app-link" href={`https://letsmetrix.com/app/${value}`} target="_blank">
            {value}
          </a>
        </Text>
      ),
      sorter: (a, b) => {
        const aValue = a.app_id || '';
        const bValue = b.app_id || '';
        return aValue.length - bValue.length;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => <Text>{value ?? '-'}</Text>,
      sorter: (a, b) => {
        const aValue = a.created_at || '';
        const bValue = b.created_at || '';
        return aValue.length - bValue.length;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value: string) => <Text>{value ?? '-'}</Text>,
    },
    {
      title: 'GA Connected',
      dataIndex: 'ga_connected',
      key: 'ga_connected',
      render: (value: boolean, record) => renderSwitch(record.app_id, value, ConnectTypeEnum.GA),
    },
    {
      title: 'Shopify Connected',
      dataIndex: 'shopify_connected',
      key: 'shopify_connected',
      render: (value: boolean, record) => renderSwitch(record.app_id, value, ConnectTypeEnum.SHOPIFY),
    },
  ];

  return (
    <div className="list-app-partner">
      <h2>
        <span onClick={() => history.push('/admin/user-management')} className="back-page">
          <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
        </span>
        List App Partner Member ({username})
      </h2>
      <Spin spinning={loading}>
        <div className="filter-add">
          <div className="button-add">
            <Button type="primary" onClick={() => setOpenAddModal(true)}>
              <PlusCircleOutlined />
              Add
            </Button>
          </div>
          <div className="filter-container">
            <div className="filter-apps">
              <div className="search_app-id">
                <Input
                  placeholder="Enter search app id"
                  value={searchAppId}
                  suffix={<SearchOutlined />}
                  variant="outlined"
                  onChange={(e) => setSearchAppId(e.target.value)}
                />
              </div>
              <div className="other-filter_apps">
                <div>
                  <span>Total Records:</span> <span>{filteredDataMember.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AntTable rowKey="_id" dataSource={filteredDataMember} columns={columns} pagination={{ pageSize: 10 }} />
      </Spin>
      <ModalAddApps
        isOpen={openAddModal}
        apps={filteredAdminApps}
        onOk={handleAddApp}
        onCancel={() => setOpenAddModal(false)}
        username={username}
        loadingAddApp={loadingAddApp}
      />
    </div>
  );
};
